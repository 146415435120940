import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`The Internet Doesn't Care About Geography`}</h1>
    <p><strong parentName="p">{`Yo!`}</strong></p>
    <p><strong parentName="p">{`Got a good one for ya today.`}</strong></p>
    <p><strong parentName="p">{`We’re getting venture #2 started - building a $1M+ online course.`}</strong></p>
    <p>{`My favorite teacher back in high school (mr beckham) worked his butt off, and only made ~$70k ish a year. That’s because his teaching could only reach people within the 4 walls of our high school.`}</p>
    <p>{`With the internet - the best teachers can reach millions of students and make millions of dollars. The founder of teachable said they have 12 teachers making over $1M+ per quarter right now.`}</p>
    <p>{`If a caveman came from the past in a time machine and wanted to know why everyone’s making so much more money on the internet - `}<strong parentName="p">{`I’d explain that with most businesses, you’re limited by geography. The internet doesn’t give a fuck about geography.`}</strong>{` If you have a winning business (like the McDonalds of the internet), You don’t have to spend a decade expanding. You expand everywhere, you’re open every hour, at nearly zero cost.`}</p>
    <p>{`At that point, the caveman would probably be like - what’s McDonald's?`}</p>
    <p>{`Anyways I digress.`}</p>
    <p><strong parentName="p">{`To get this course started, we:`}</strong></p>
    <p><strong parentName="p">{`1) Picked a topic`}</strong>{` (learning how to buy profitable businesses) that I am personally interested in learning (I am my customer).`}</p>
    <p><strong parentName="p">{`2) Building our audience`}</strong>{` BEFORE we’re building our product.`}</p>
    <p>{`I don’t always advise this. And might change my mind… but for now I think the right strategy is to build a free email list - and then upsell them a course.`}</p>
    <p>{`I prefer this strategy, but if it’s going too slow or not working, we’ll ditch the audience building section and use paid ads to drive traffic straight to the course. The free audience is great if it works, because it builds trust. If people get value from me 5 times for free, they are highly likely to expect my paid course to be high quality too.`}</p>
    <p>{`For building this email list from scratch - we’re going to create a lead magnet. Basically, a freebie giveaway (like an ebook or PDF) that you get in exchange for your email address.`}</p>
    <p>{`This shit isn’t rocket science. Lots of people do it. In this email, we’re going to show you our first stab at doing it.`}</p>
    <p><strong parentName="p">{`My main man Ben (aka the guy who does all the hard work around here) recorded this Loom screencast of him explaining the tools he stitches together to make a lead magnet in under 20 minutes (okay, actually 22 minutes from start to finish).`}</strong></p>
    <p><strong parentName="p">{`Check it out (just click `}<a parentName="strong" {...{
          "href": "https://www.loom.com/share/6943cf1eb4f04d25a2ffdc3250c2dd73"
        }}>{`here`}</a>{`)`}</strong></p>
    <div style={{
      "position": "relative",
      "paddingBottom": "62.5%",
      "height": "0"
    }}><iframe src="https://www.loom.com/embed/6943cf1eb4f04d25a2ffdc3250c2dd73" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></div>
    <br />
    <p>{`Here's the link to the library of lead magnets we've been using as inspiration, too. `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.dropbox.com/sh/jpnsovmmry5212n/AAAa-1FFCbbjiGjRg42bQD6ha?dl=0"
        }}>{`Just click here to get it`}</a></strong>{`.`}</p>
    <p>{`I’m back with more on Sunday.`}</p>
    <p>{`Until Sunday,`}<br parentName="p"></br>{`
`}{`Shaan`}</p>
    <p>{`P.S. if you want some fun reading over the weekend - check this out:`}<br parentName="p"></br>{`
`}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://minesafetydisclosures.com/blog/newyorktimes"
        }}>{`https://minesafetydisclosures.com/blog/newyorktimes`}</a></strong></p>
    <p>{`Not only is this an interesting view on the NYT - this guy makes `}<strong parentName="p">{`EXCELLENT`}</strong>{` slide decks. He did one on Costco awhile back that I shared with like 20 of my employees and just said “this is how crisp your decks should be.” Easier said than done. Check it out to see what a top tier deck flows like.`}</p>
    <Links links={[{
      title: LinkLabel.Multimedia,
      links: [{
        url: 'https://www.loom.com/share/6943cf1eb4f04d25a2ffdc3250c2dd73',
        label: 'Building Lead Magnets Loom'
      }, {
        url: 'https://www.dropbox.com/sh/jpnsovmmry5212n/AAAa-1FFCbbjiGjRg42bQD6ha?dl=0',
        label: 'Lead Magnet Library'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://minesafetydisclosures.com/blog/newyorktimes',
        label: "Mine Safety Disclosures NYT Deck"
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.prototyping_the_third_door.label,
      slug: slugify(postMap.prototyping_the_third_door.slug)
    }} nextButton={{
      text: postMap.picking_the_mission_for_the_week.label,
      slug: slugify(postMap.picking_the_mission_for_the_week.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      